'use strict';

$(function () {
    // Flip cards
    if ($(".c-082").length > 0) {
        $('.flipcard').on({
            mouseenter: function mouseenter() {
                $('.outer-container', this).css('opacity', '1');
                $('.inner-container', this).css('opacity', '0');
            },
            mouseleave: function mouseleave() {
                $('.outer-container', this).css('opacity', '0');
                $('.inner-container', this).css('opacity', '1');
            }
        });
    }

    // Pop Quiz
    $('.c-081 .grid .box').on('click', function (e) {
        $(this).toggleClass("selected");
        e.preventDefault();
    });
    $('.submit-answer').on('click', function (e) {
        $('.img-container .img-default, .quiz').addClass('hide').fadeOut(1000).fadeIn(0);
        $('.img-container .img-changed, .green-box-container').removeClass('hide').fadeOut(0).fadeIn(1000);
    });

    // Savings Calculator
    $('.c-083 .back-link').on('click', function (e) {
        $('.step2-container, .step3-container').toggleClass('hide');
        $(".blue-box").addClass("hide");
        customScrollToByClass("c-083");
    });
    $('.next-step').on('click', function (e) {
        $('.step1-container, .step2-container').toggleClass('hide');
    });

    $('#submit-calculator').on('click', function (e) {
        customScrollToByClass("c-083");
        var totalSavings = 0;
        $(".c-083 .grid .box.selected").each(function (index) {
            totalSavings += parseInt($(this).data("savings"));
            $(".c-083 .blue-box[data-bluebox-id='" + $(this).data("card-id") + "']").toggleClass('hide');
        });
        $(".grand-total span").text(totalSavings);
        $('.step2-container, .step3-container').toggleClass('hide');
    });
    $('.c-083 .grid .box').on('click', function (e) {
        $(this).toggleClass("selected");
        e.preventDefault();
    });

    // 4 animated cards
    if ($('.card-animated').length > 0) {
        $('.card-animated').last().css('margin-bottom', '50px');
        var observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    $('.card-animated').addClass("card-animation");
                    $('.card-animated').each(function (index, element) {
                        $(this).css('animation-delay', index / 1.8 + 's');
                    });
                    return;
                }
            });
        });
        observer.observe(document.querySelector('.card-animated-container'));
    }

    // Share Twitter/facebook
    var url = window.location.href;
    var twitterShare = document.querySelector('[data-js="twitter-share"]');
    if ($(twitterShare).length > 0) {
        twitterShare.onclick = function (e) {
            e.preventDefault();
            var twitterWindow = window.open('https://twitter.com/share?url=' + url, 'twitter-popup', 'height=350,width=600');
            if (twitterWindow.focus) {
                twitterWindow.focus();
            }
            return false;
        };
    }

    var facebookShare = document.querySelector('[data-js="facebook-share"]');
    if ($(facebookShare).length > 0) {
        facebookShare.onclick = function (e) {
            e.preventDefault();
            var facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, 'facebook-popup', 'height=350,width=600');
            if (facebookWindow.focus) {
                facebookWindow.focus();
            }
            return false;
        };
    }

    //Interactive-Questionnaire 
    $(window).on("resize", function (event) {
        if ($(".c-084").length > 0) {
            //fix height if resized
            var newheight = $(".c-084 .question-container:visible").find(".answer").outerHeight();
            $(".c-084 .question-container:visible").height(newheight);
        }
    });
    if ($('.c-084').length > 0) {
        $(".progress-container").each(function (index) {
            $('.progress-container').eq(index).children().eq(index).addClass("progress-dot-active");
        });
    }
    $('.c-084 #startButton').on('click', function (e) {
        $(".intro").parent().hide();
        $(".question-container").first().show();
        customScrollToByClass("c-084");
    });
    $('.c-084 .btn-yes').on('click', function (e) {
        $(this).closest(".question-container").find(".question").addClass("out");
        var answer = $(this).closest(".question-container").find(".answer.yes");
        answer.addClass("slide-in");
        answer.attr('data-selected', 'yes');
        $(this).closest(".question-container").height(answer.outerHeight());
    });
    $('.c-084 .btn-no').on('click', function (e) {
        $(this).closest(".question-container").find(".question").addClass("out");
        var answer = $(this).closest(".question-container").find(".answer.no");
        answer.addClass("slide-in");
        answer.attr('data-selected', 'no');
        $(this).closest(".question-container").height(answer.outerHeight());
    });
    $('.c-084 .forward').on('click', function (e) {
        $(this).closest(".question-container").hide().next().fadeIn();
        customScrollToByClass("c-084");
    });
    $('.c-084 .backward').on('click', function (e) {
        $(this).closest(".question-container").find(".question").removeClass("out").css("height", "");
        $(this).closest(".answer").removeClass("slide-in").removeAttr('data-selected');
        customScrollToByClass("c-084");
    });
    $('.c-084 .btn-show-result').on('click', function (e) {
        $(".c-084 .all-questions").hide();

        var countYes = $(".c-084 .answer[data-selected='yes']").length,
            resultScore = $(".c-084 span[data-result-score]").data("result-score");

        if (countYes >= resultScore) {
            $(".c-084 .result-container.yes").show();
        } else {
            $(".c-084 .result-container.no").show();
        }
        customScrollToByClass("c-084");
    });
    $('.c-084 .btn-retake').on('click', function (e) {
        $(".c-084 .all-questions .answer").removeAttr('data-selected');
        $(".c-084 .result-container").hide();
        $(".c-084 .all-questions .question-container").removeAttr("style");
        $(".c-084 .all-questions .question").removeClass("out");
        $(".c-084 .all-questions .answer").removeClass("slide-in");
        $(".intro").parent().show();
        $(".c-084 .all-questions").show();
        customScrollToByClass("c-084");
    });
});