"use strict";

$(function () {
    'use strict';

    if ($(".contest").length > 0) {
        if ($("#contest").length > 0) {
            var $contestForm = $("#contest-form"),
                disclaimerContainer = $("#disclaimer"),
                fileProps = $contestForm.find("#file-props"),
                customErrorsContainer = $contestForm.find("#custom-errors-container"),
                fileSizeError = $contestForm.find("#file-size-error"),
                fileName = $contestForm.find(".file-name"),
                submitButton = $("#submit"),
                rotate = 0;

            $("input[type=checkbox]").change(function () {
                if ($(this).prop('checked')) {
                    submitButton.removeAttr("disabled");
                } else {
                    submitButton.attr("disabled", "disabled");
                }
            });
            $("#rotate").on("click", function () {
                $("#preview img").css({ "transform": "rotate(" + (rotate += 90) + "deg)" });
            });

            $("#file").change(function () {
                var file = this.files[0];
                if (file != undefined) {
                    disclaimerContainer.show();
                    var reader = new FileReader(),
                        rule_file_size = $("#file").data("rule-filesize");
                    if (file.size < rule_file_size) {
                        fileSizeError.hide();
                        $("#file-error").hide();
                        fileName.text(file.name).show();
                        reader.onload = function (e) {
                            $('#preview img').attr('src', e.target.result);
                        };
                        reader.readAsDataURL(file);
                    } else {
                        fileName.text("").hide();
                        var fileSizeErrorText = $("#file").data("msg-filesize") + (" - Your file is " + (file.size / 1000000).toFixed(2) + "  MB");
                        fileSizeError.text(fileSizeErrorText).show();
                        $("#file").val('');
                        $('#preview img').attr('src', "");
                    }
                    fileProps.fadeIn(200);
                } else {
                    //reset view
                    disclaimerContainer.hide();
                    fileProps.hide();
                    fileSizeError.hide();
                    fileName.text("").hide();
                }
            });

            submitButton.on("click", function () {
                $contestForm.validate({
                    onfocusout: function onfocusout(element) {
                        this.element(element);
                    },
                    errorElement: "div",
                    errorPlacement: function errorPlacement(error, element, param) {
                        if (element.prop('name') === 'UploadedFile') {
                            fileSizeError.empty();
                            customErrorsContainer.append(error);
                        } else {
                            setupErrorPlacementValidation(error, element);
                        }
                    },
                    submitHandler: function submitHandler(form) {
                        $contestForm.find(".fa-spinner").show();
                        submitButton.addClass("accent");
                        form.submit();
                    }
                });
            });
        } //#contest

        if ($("#thumbs-view").length > 0) {
            //VOTE PAGE
            //let endpoint = 'http://integ.neamb.com/api/VoteService',
            var endpoint = $(location).attr('origin') + "/api/VoteService",
                ajax_error = "Something went wrong, please refresh the page and try again!",
                voted_message = $(".contest").data("alreadyvote") || "You already vote, please try tomorrow",
                vote_Obj = {
                contest_Id: $(".contest").data("contestid"),
                loader: $(".loader"),
                element: "",
                allowed_to_vote: "true",
                ajax_type: "POST",
                ajax_url_GetUserVote: endpoint + "/GetUserVote",
                ajax_url_AddUserVote: endpoint + "/AddUserVote",
                ajax_data: {},
                ajax_dataType: 'json',
                close_swal: function close_swal() {
                    setTimeout(function () {
                        swal.close();
                    }, 5000);
                },
                loader_effect: function loader_effect() {
                    $(this.element).parents(".thumb").find(".vote").before(this.loader);
                    this.loader.fadeIn();
                },
                hide_confirmation: function hide_confirmation() {
                    $(".confirmation").addClass("hidden-confirmation");
                    this.loader.remove();
                },
                toggle_confirmation: function toggle_confirmation() {
                    this.hide_confirmation();
                    $(this.element).parents(".thumb").find(".confirmation").toggleClass("hidden-confirmation");
                },
                allow_vote: function allow_vote() {
                    this.ajax_data = {
                        "contestId": this.contest_Id
                    };
                    var request = ajaxRequest(this.ajax_type, this.ajax_url_GetUserVote, this.ajax_data, this.ajax_dataType);
                    request.promise(this.loader_effect()).done(function (data) {
                        /*data = {
                            "SubmissionId" : "",
                        }*/
                        data.SubmissionId ? (swal(voted_message), this.toggle_confirmation()) : this.register_vote();
                    }.bind(this)) //done
                    .fail(function (xhr) {
                        swal(ajax_error);
                        this.toggle_confirmation();
                    }.bind(this));
                }, //vote_function
                register_vote: function register_vote() {
                    this.ajax_data = {
                        "ContestId": this.contest_Id,
                        "SubmissionId": $(this.element).parents(".thumb").data("submissionid")
                    };

                    var request = ajaxRequest(this.ajax_type, this.ajax_url_AddUserVote, this.ajax_data, this.ajax_dataType);
                    request.promise(this.loader_effect()).done(function (data) {
                        /*data = {
                            "ContestId": "39D922D0-F379-49B4-9F06-27FDD5557410",
                            "SubmissionId": "2E2CF3E4-181D-4717-A8E8-3D09FF1F954C",
                            "Total": 1
                        };*/
                        var all_voted_elements = $(document).find(".thumb[data-id=\"" + $(this.element).parents(".thumb").data("id") + "\"]");
                        data.Total ? (this.hide_confirmation(), $.each(all_voted_elements, function () {
                            $(this).find(".count").text(data.Total);
                            $(this).find(".vote").addClass("voted");
                            $('#ThankYou').modal('show');
                        })) : (this.hide_confirmation(), swal(data.Message));
                    }.bind(this)) //done
                    .fail(function (xhr) {
                        this.toggle_confirmation();
                        swal(ajax_error);
                    }.bind(this));
                } //register_function
                //vote_Obj
                //LIGHTBOX
            };var index = 0,
                slides = 0,
                lightbox = {
                context: $("#lightbox"),
                prev: $(".prev"),
                next: $(".next"),
                thumb: "",
                src: "",
                current: "",
                total: $("#thumbs-view .thumb").length,
                init: function init() {},
                setImg: function setImg() {
                    this.context.find("#items-view > div").html(this.thumb);
                },
                show: function show() {
                    this.context.fadeIn();
                    this.setImg();
                    this.init_navigation();
                },
                hide: function hide() {
                    this.context.fadeOut();
                },
                init_navigation: function init_navigation() {
                    $(".controls").show();
                    if (this.current == 0) {
                        this.prev.hide();
                    } else if (this.current == this.total - 1) {
                        this.next.hide();
                    }
                },
                navigation: function navigation(direction) {
                    var view = direction == "next" ? this.current += 1 : view = this.current -= 1;
                    this.init_navigation();
                    this.thumb = $("#thumbs-view .thumb[data-id=\"" + view + "\"]").clone();
                    this.setImg();
                } //navigation
                //lightbox
                //EVENTS
                //VOTE
            };$(document).on("click", ".vote , .no , .yes", function () {
                vote_Obj.element = this;
                if ($(this).hasClass('vote')) {
                    vote_Obj.toggle_confirmation();
                } else if ($(this).hasClass('no')) {
                    vote_Obj.hide_confirmation();
                } else {
                    vote_Obj.toggle_confirmation();
                    vote_Obj.allow_vote();
                }
            });

            //LIGTHBOX	
            $(".row .thumb img").on("click", function (e) {
                e.preventDefault();
                lightbox.thumb = $(this).parents(".thumb").clone();
                lightbox.current = $(this).parents(".thumb").data("id");
                lightbox.show();
            });

            $(".close").on("click", function (e) {
                e.preventDefault();
                lightbox.hide();
            });

            $(".controls").on("click", function (e) {
                e.preventDefault();
                var direction = $(this).hasClass("next") ? "next" : "prev";
                lightbox.navigation(direction);
            });

            var hammertime = new Hammer(document.getElementById('items-view'));
            hammertime.on('swipe', function (ev) {
                var direction = ev.direction == 2 ? "next" : "prev";
                if ($("." + direction).is(":visible")) {
                    $("." + direction).trigger("click");
                }
            });
        } //#thumb-view
    } //if contest form exists
});