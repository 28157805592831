"use strict";

$(function () {
    if ($("#subscriptions").length > 0) {
        var loader = $(".loader"),
            endpoint = $(location).attr('origin'),

        //endpoint = "http://integ.neamb.codm",
        data = {
            "listId": 0,
            "newStatus": 0
        };
        var subscriptions = {
            errorBar: $(".error-bar"),
            checkMark: '<p class="p-b-sm"><img src="/assets/neamb/images/Checkmark-Icon.png" alt=""><span class="p-l-sm">You are subscribed</span></p>',
            error: function error() {
                this.errorBar.addClass("show");
                $("html, body").animate({ scrollTop: 0 }, "slow");
            },
            setButton: function setButton(el) {
                this.errorBar.removeClass("show");
                el = $(el);
                el.addClass("btn-blue");
                el.text(el.data("subscribelabel"));
                el.siblings("p").hide();
                el.removeClass('disable');
            },
            unSetButton: function unSetButton(el) {
                this.errorBar.removeClass("show");
                el = $(el);
                el.removeClass("btn-blue");
                el.text(el.data("unsubscribelabel"));
                if (!el.siblings("p").length > 0) {
                    el.before(this.checkMark);
                }
                el.siblings("p").show().find("span").text(el.data("checklabel"));
                el.removeClass('disable');
            },
            activateSpinner: function activateSpinner(el) {
                el = $(el);
                el.addClass('disable');
                el.append(' <i style="display: none;" class="fa fa-spinner fa-spin"></i>');
                el.find(".fa-spinner").show();
            },
            requestData: function requestData(data) {
                return new Promise(function (resolve, reject) {
                    $.ajax({
                        data: data,
                        dataType: 'json',
                        async: true,
                        type: 'POST',
                        url: endpoint + "/api/SettingsAndSubs/ChangeSubscription",
                        success: function success(data) {
                            resolve(data);
                        },
                        error: function error(_error) {
                            reject(_error);
                        }
                    });
                });
            }, //requestData,
            permissionStopEmail: function permissionStopEmail() {
                return new Promise(function (resolve, reject) {
                    $.ajax({
                        dataType: 'json',
                        type: 'POST',
                        url: endpoint + "/api/PermissionEmail/StopEmail",
                        success: function success(data) {
                            resolve(data);
                        },
                        error: function error(_error2) {
                            reject(_error2);
                        }
                    });
                });
            },
            permissionResumeEmail: function permissionResumeEmail() {
                return new Promise(function (resolve, reject) {
                    $.ajax({
                        dataType: 'json',
                        type: 'POST',
                        url: endpoint + "/api/PermissionEmail/ResumeEmail",
                        success: function success(data) {
                            resolve(data);
                        },
                        error: function error(_error3) {
                            reject(_error3);
                        }
                    });
                });
            } //subscriptions
        };$(document).on("click", ".ctas .btn", function () {
            var _this = this;

            subscriptions.activateSpinner(this);
            if (!$(this).hasClass("permissionAction")) {
                var id = $(this).data("id"),
                    status = $(this).data("status");
                data.newStatus = status == 0 ? 3 : 0;
                data.listId = id;
                $(this).data("status", data.newStatus);
                subscriptions.requestData(data).then(function (data) {
                    if (status == 0) {
                        subscriptions.setButton(_this);
                        dataLayerPush(gtm = {
                            'event': 'account',
                            'accountSection': 'settings & subscriptions',
                            'accountAction': "" + $(_this).closest('.panel-cta').find("h4").text(),
                            'ctaText': 'Unsubscribe'
                        });
                    } else if (status == 3) {
                        subscriptions.unSetButton(_this);
                        dataLayerPush(gtm = {
                            'event': 'account',
                            'accountSection': 'settings & subscriptions',
                            'accountAction': "" + $(_this).closest('.panel-cta').find("h4").text(),
                            'ctaText': 'Subscribe'
                        });
                    }
                }).catch(function (error) {
                    subscriptions.error();
                });
            } else {
                var isSuscribed = $(this).hasClass("subscribed");
                if (isSuscribed) {
                    subscriptions.permissionStopEmail().then(function (data) {
                        subscriptions.setButton(_this);
                        $(_this).removeClass("subscribed");
                        dataLayerPush(gtm = {
                            'event': 'account',
                            'accountSection': 'settings & subscriptions',
                            'accountAction': "" + $(_this).closest('.panel-cta').find("h4").text(),
                            'ctaText': 'Unsubscribe'
                        });
                    }).catch(function (error) {
                        subscriptions.error();
                    });
                } else {
                    subscriptions.permissionResumeEmail().then(function (data) {
                        subscriptions.unSetButton(_this);
                        $(_this).addClass("subscribed");
                        dataLayerPush(gtm = {
                            'event': 'account',
                            'accountSection': 'settings & subscriptions',
                            'accountAction': "" + $(_this).closest('.panel-cta').find("h4").text(),
                            'ctaText': 'Subscribe'
                        });
                    }).catch(function (error) {
                        subscriptions.error();
                    });
                }
            }
        });
    } //if
}); //function