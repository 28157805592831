'use strict';

function loaderFormSubmitButton() {
    var submitButton = document.querySelector('input[type=submit]');
    var spinner = document.createElement('span');
    spinner.setAttribute('class', 'loader');
    submitButton.setAttribute('disabled', true);
    submitButton.style.cursor = 'wait';
    submitButton.parentNode.appendChild(spinner);
    return true;
}

function resetFormSubmitButton() {
    var submitButton = document.querySelector('input[type=submit]');
    $(submitButton).removeAttr("disabled");
    submitButton.style.cursor = 'pointer';
    $("span.loader").remove();
    return true;
}

function resetSubmitButton(e) {
    var submitButtons = e.target.form.getElementsByClassName('submit-button');
    for (var i = 0; i < submitButtons.length; i++) {
        submitButtons[i].disabled = false;
    }
}

function addChangeHandler(elements) {
    for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('change', resetSubmitButton);
    }
}

function addRestrictedStateValidation() {
    var parent = document.getElementById("formElement5");
    var span = document.createElement('span');
    span.className = "LV-custom-validation";
    span.id = "validation-restricted-state";
    span.style.display = "none";
    span.textContent = "Please select a state other than (AK, HI, MA, MI, NY, PR or WI)";
    $(span).insertAfter(parent);
}

function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.search);
    if (results == null) return "";else return decodeURIComponent(results[1].replace(/\+/g, " "));
}

function getUserprofileInformation() {
    var url = "api/UserState/GetUserprofileInformation";
    $.ajax({
        type: "GET",
        url: url,
        cache: false,
        success: function success(data) {
            if (data.Status == 5 || data.Status == 6 || data.Status == 7) {
                fillFormData(data);
            }
        },
        complete: function complete(data) {}
    });
}

function fillFormData(data) {
    if (data) {
        $('#form912 #fe22034').val(data.Profile.FirstName);
        $('#form912 #fe22035').val(data.Profile.LastName);
        $('#form912 #fe22036').val(data.Profile.Email);
        $('#form912 #fe22037').val(data.Profile.StreetAddress);
        $('#form912 #fe22038').val(data.Profile.City);
        $('#form912 #fe22040').val(data.Profile.ZipCode);
        $('#form912 #fe22041').val(data.Profile.Phone).trigger('input');
        $("#form912 #fe22039").val(data.Profile.StateCode);
    }
}

function recaptcha_callback(recaptcha_response) {
    if (recaptcha_response) {
        $(".recaptcha-message").hide();
    }
}

$(function () {

    if ($('body').is('.lead-form')) {
        getUserprofileInformation();

        var form = document.getElementById('form912');
        addChangeHandler(form.getElementsByTagName('input'));
        addChangeHandler(form.getElementsByTagName('select'));
        addChangeHandler(form.getElementsByTagName('textarea'));
        var nodes = document.querySelectorAll('#form912 input[data-subscription]');
        if (nodes) {
            for (var i = 0, len = nodes.length; i < len; i++) {
                var status = nodes[i].dataset ? nodes[i].dataset.subscription : nodes[i].getAttribute('data-subscription');
                if (status === 'true') {
                    nodes[i].checked = true;
                }
            }
        };
        var nodes = document.querySelectorAll('#form912 select[data-value]');
        if (nodes) {
            for (var i = 0; i < nodes.length; i++) {
                var node = nodes[i];
                var selectedValue = node.dataset ? node.dataset.value : node.getAttribute('data-value');
                if (selectedValue) {
                    for (var j = 0; j < node.options.length; j++) {
                        if (node.options[j].value === selectedValue) {
                            node.options[j].selected = 'selected';
                            break;
                        }
                    }
                }
            }
        }
        this.getParentElement = function (list) {
            return list[list.length - 1].parentElement;
        };
        var dom0 = document.querySelector('#form912 #fe22034');
        var fe22034 = new LiveValidation(dom0, {
            validMessage: "", onlyOnBlur: false, wait: 300
        });
        fe22034.add(Validate.Presence, {
            failureMessage: "This field is required"
        });
        var dom1 = document.querySelector('#form912 #fe22035');
        var fe22035 = new LiveValidation(dom1, {
            validMessage: "", onlyOnBlur: false, wait: 300
        });
        fe22035.add(Validate.Presence, {
            failureMessage: "This field is required"
        });
        var dom2 = document.querySelector('#form912 #fe22036');
        var fe22036 = new LiveValidation(dom2, {
            validMessage: "", onlyOnBlur: false, wait: 300
        });
        fe22036.add(Validate.Length, {
            tooShortMessage: "Invalid length for field value", tooLongMessage: "Invalid length for field value", minimum: 1, maximum: 100
        });
        fe22036.add(Validate.Presence, {
            failureMessage: "This field is required"
        });
        fe22036.add(Validate.Format, {
            pattern: /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i, failureMessage: "A valid email address is required"
        });
        var dom3 = document.querySelector('#form912 #fe22037');
        var fe22037 = new LiveValidation(dom3, {
            validMessage: "", onlyOnBlur: false, wait: 300
        });
        fe22037.add(Validate.Presence, {
            failureMessage: "This field is required"
        });
        var dom4 = document.querySelector('#form912 #fe22038');
        var fe22038 = new LiveValidation(dom4, {
            validMessage: "", onlyOnBlur: false, wait: 300
        });
        fe22038.add(Validate.Presence, {
            failureMessage: "This field is required"
        });
        var dom5 = document.querySelector('#form912 #fe22039');
        var fe22039 = new LiveValidation(dom5, {
            validMessage: "", onlyOnBlur: false, wait: 300
        });
        fe22039.add(Validate.Presence, {
            failureMessage: "This field is required"
        });
        var dom6 = document.querySelector('#form912 #fe22040');
        var fe22040 = new LiveValidation(dom6, {
            validMessage: "", onlyOnBlur: false, wait: 300
        });
        fe22040.add(Validate.Length, {
            tooShortMessage: "Invalid Zip Code", tooLongMessage: "Invalid Zip Code", minimum: 5, maximum: 5
        });
        fe22040.add(Validate.Presence, {
            failureMessage: "This field is required"
        });
        var dom7 = document.querySelector('#form912 #fe22041');
        var fe22041 = new LiveValidation(dom7, {
            validMessage: "", onlyOnBlur: false, wait: 300
        });
        fe22041.add(Validate.Presence, {
            failureMessage: "This field is required"
        });
        fe22041.add(Validate.Length, {
            tooShortMessage: "Invalid length for field value", tooLongMessage: "Invalid length for field value", minimum: 13, maximum: 13
        });
        var dom8 = document.querySelectorAll('#form912 [name="optLob"]');
        var optLob = new LiveValidation(dom8, {
            validMessage: "", onlyOnBlur: false, wait: 300, insertAfterWhatNode: this.getParentElement(dom8), isGroup: true
        }, true);

        $('input[name="txtHome_Phone"]').mask('(000)000-0000');

        $("#fe22053").val(getParameterByName('utm_campaign'));
        $("#fe22054").val(getParameterByName('utm_source'));
        $("#fe22055").val(getParameterByName('utm_term'));

        if (getParameterByName("qtype")) {
            $("input[name=optLob][value=" + getParameterByName("qtype") + "]").prop('checked', true);
        }

        var restrictedStates = ["AK", "HI", "MA", "MI", "NY", "WI", "PR", "NC"];
        var hasErrorForState = false;
        addRestrictedStateValidation();

        $("#fe22039").change(function () {
            var isRestrictedState = restrictedStates.includes($(this).val());
            if (isRestrictedState) {
                hasErrorForState = true;
                document.getElementById("validation-restricted-state").style.display = "block";
                $('#CalCasRestrictedStates').modal('show');
            } else {
                hasErrorForState = false;
                document.getElementById("validation-restricted-state").style.display = "none";
            }
        });

        $("#form912").submit(function (e) {
            e.preventDefault();
            var form = $(this);
            var url = "https://s1744479642.t.eloqua.com/e/f2";
            var hasErrors = $("#form912 :input").hasClass("LV_invalid_field");

            var captchaCheck = grecaptcha.getResponse();
            if (!captchaCheck) {
                $(".recaptcha-message").show();
            } else {
                $(".recaptcha-message").hide();
            }
            if (!hasErrors && !hasErrorForState && captchaCheck) {
                loaderFormSubmitButton();

                var captchaVerification = grecaptcha.getResponse();
                if (captchaVerification.length) {
                    $.ajax({
                        type: "POST",
                        url: "api/Recaptcha/ValidateUserToken",
                        data: "{responseToken: '" + captchaVerification + "'}",
                        contentType: "application/json; charset=utf-8",
                        dataType: "json",
                        success: function success(apiResponse) {
                            if (apiResponse.Success) {
                                var formData = form.serialize();

                                $.ajax({
                                    type: "POST",
                                    url: url,
                                    data: formData,
                                    complete: function complete(data) {
                                        $('#CalCasConfirmation').modal('show');
                                        $('#form912').trigger("reset");
                                        grecaptcha.reset();
                                        resetFormSubmitButton();
                                    }
                                });
                            } else {
                                $(".recaptcha-message").show();
                            }
                        }
                    });
                }
            }
        });
    }

    $(".calcas-hero-form").submit(function (e) {
        e.preventDefault();
        var valueSelected = $('select[name="quote-type"] option:selected').val();
        var textSelected = $('select[name="quote-type"] option:selected').text();
        var querystring = 'qtype=' + valueSelected;
        var href = $(".calcas-hero-form").data("calcas-url");

        if (href) {
            dataLayerPush({
                'clickHref': href,
                'event': 'auto home quote widget',
                'ctaText': 'get a quote',
                'clickText': $.trim(textSelected)
            }, this);

            href += (href.match(/\?/) ? '&' : '?') + querystring;
            window.open(href, '_blank');
        }
    });
});