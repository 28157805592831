"use strict";

jQuery(function () {
    $(".navbar-toggle").on("click", function () {
        $(this).toggleClass("collapsed");
    });
    $("#searchButtonMobile").on("click", function () {
        $(this).toggleClass("collapsed");
        $("#search-mobile").focus();
    });

    $('.dropdown-submenu > a').on("click", function (e) {
        $(this).next('ul').toggle();
        $(this).toggleClass('open');
        e.stopPropagation();
        e.preventDefault();
    });

    $('.profile-menu-accordion').on("click", function (e) {
        $(this).toggleClass("active");
        $(this).next().toggle();
        e.stopPropagation();
        e.preventDefault();
    });

    if ($('.sticky').length > 0) {
        var distance = $('.sticky').offset().top;
        if ($(".items-fixed.buttons > a").length == 1) {
            $("#middle-menu ul").addClass("single");
        }
        $(window).scroll(function () {
            if ($(this).scrollTop() >= distance) {
                $('.sticky').addClass('fixed');
                $('body').addClass('body-fixed');
            } else {
                $('.sticky').removeClass('fixed');
                $('body').removeClass('body-fixed');
            }
        });
    }

    $(".profile-toggle").on("click", function () {
        $(this).toggleClass("collapsed");
        var isOpen = $(this).hasClass("collapsed");
        if (isOpen) {
            showMobileOverlay();
            $("#mobile-menu").addClass("hidden");
            $("#searchButtonMobile").addClass("collapsed");
            $("#profile-menu").removeClass("hidden");
            $(".nav-center").removeClass('show');
            $('.navbar-toggler').addClass('collapsed');
            $('.navbar-toggle').addClass('collapsed');
            $("#mobile-search-menu").addClass("hidden");
            // change icon for mobile menu
            $('.navbar-toggler').addClass('collapsed');
        } else {
            hideOverlay();
            hideOverlayLayers();
        }
    });

    var $loginCurtain = $('.login-curtain');
    var $loginDesktopLink = $('.login-desktop-link');
    var $coldMenu = $('.account-menu');

    $loginCurtain.on({
        mouseenter: function mouseenter() {
            $(this).prev().addClass("open");
        },
        mouseleave: function mouseleave() {
            $(this).hide().prev().removeClass("open");
        }
    });
    if ($(".cold").length > 0) {
        $loginDesktopLink.on({
            mouseenter: function mouseenter() {
                $coldMenu.show();
            },
            mouseleave: function mouseleave() {
                $coldMenu.hide();
            }

        });
        $coldMenu.on({
            mouseenter: function mouseenter() {
                $coldMenu.show();
            },
            mouseleave: function mouseleave() {
                $coldMenu.hide();
            }
        });
    }

    if ($(".show-login-curtain").length > 0) {
        $loginCurtain.show().delay(5000).fadeOut();
        $loginCurtain.on("mouseenter", function () {
            $(this).stop(true, true);
        });
    }

    // Functions for new account menu
    $("#close-message-detailed").on("click", function (e) {
        $("#message-detailed-menu").hide();
        $("#messages-preview").show();
        e.stopPropagation();
        // $("nav-right-desktop a.dropdown-toggle").attr("aria-expanded","true");
        // $("nav-right-desktop div.dropdown").addClass("open");
    });

    $("#close-message-center").on("click", function (e) {
        $("#message-detailed-menu").hide();
        $("#message-center-menu").hide();
        $("#sign-in-menu").show();
        e.stopPropagation();
    });

    $('a.important-messages').on('click', function (e) {
        $("#sign-in-menu").hide();
        $("#message-center-menu").show();
        $("#messages-preview").show();
        e.preventDefault();
        e.stopPropagation();
    });

    $('a.mobile-important-messages').on('click', function (e) {
        $(this).next('ul').toggle();
        e.stopPropagation();
        e.preventDefault();
    });

    // more 
    $('span.blue').on('click', function (e) {
        $('.message').hide();
        $(this).parents('.message').show().addClass('message-expanded');
        $(this).hide();
        $('.important-message__see-less').show();
        e.stopPropagation();
    });

    // see less
    $('.important-message__see-less').on('click', function (e) {
        importantMessageSeeLess();
        e.stopPropagation();
    });

    //show more only if exceeds parents width
    $('#navbarMessagesMenuLink').on('click', function (e) {
        isTextOverflowing();
    });
    // Finish functions for new account menu
});
// 

window.addEventListener('resize', function () {
    isTextOverflowing();
    if ($('.message').hasClass('message-expanded')) {
        importantMessageSeeLess();
    }
});

function importantMessageSeeLess() {
    $('.message').show().removeClass('message-expanded');
    $('span.blue').show();
    $('.important-message__see-less').hide();
    isTextOverflowing();
}

function isTextOverflowing() {
    $("span.blue").hide();
    var $tempElement = $(".message-description");
    var $myElements = $('p.message-body');

    $myElements.each(function (index, element) {
        var fatherWidth = $tempElement[index].getBoundingClientRect().width;
        var elementWidth = element.getBoundingClientRect().width;
        if (elementWidth > 0) {
            if (elementWidth >= fatherWidth) {
                $(element).siblings('.blue').show();
            }
        }
    });
}

function openNavMenu() {
    showMobileOverlay();

    $("#mobile-menu").removeClass("hidden");
    $("#profile-menu").addClass("hidden");
    $("#searchButtonMobile").addClass("collapsed");
    $("#mobile-search-menu").addClass("hidden");

    // change icon for profile menu
    $(".profile-toggle").removeClass("collapsed");
}
function openSearchMenu() {

    if ($('#searchButtonMobile').hasClass('collapsed')) {
        showMobileOverlay();
        $("#mobile-menu").addClass("hidden");
        $("#profile-menu").addClass("hidden");
        $(".profile-toggle").removeClass("collapsed");
        $("#mobile-search-menu").removeClass("hidden");
        $(".navbar-toggle").addClass("collapsed");
    } else {
        // $("#searchButtonMobile").addClass("collapsed");
        closeSearchMenu();
    }
}

function closeNavMenu() {
    hideOverlay();
    hideOverlayLayers();
    $('.navbar-toggle').addClass('collapsed');
}
function closeSearchMenu() {
    hideOverlay();
    hideOverlayLayers();
    $('.navbar-toggle').addClass('collapsed');
}

function showMobileOverlay() {
    $('html').addClass("disableScrollSafari");
    $('body').addClass("disableScrollSafari");
    $(".overlay-mobile").css("opacity", "1");
    $(".overlay-mobile").css("height", "100%");
    $(".overlay-mobile").css("height", getOverlayHeight());
}

function hideOverlay() {
    $('html').removeClass("disableScrollSafari");
    $('body').removeClass("disableScrollSafari");
    $(".overlay-mobile").css("opacity", "0");
    $(".overlay-mobile").css("height", "0%");
}

function hideOverlayLayers() {
    $("#mobile-menu").addClass("hidden");
    $("#profile-menu").addClass("hidden");
    $("#mobile-search-menu").addClass("hidden");
}

function getOverlayHeight() {
    var top = document.getElementsByClassName('overlay-mobile')[0].offsetTop;
    return "calc(100% - " + top + "px)";
}