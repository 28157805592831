'use strict';

$(function () {
	jQuery.validator.addMethod('alphanumber', function (value, element) {
		return this.optional(element) || /^[-.,' a-zA-Z0-9]{2,}$/i.test(value);
	});

	jQuery.validator.addMethod('alphanumberwithspace', function (value, element) {
		return this.optional(element) || /^[a-zA-Z0-9 ]+$/i.test(value);
	});

	jQuery.validator.addMethod('alphanumbernospaces', function (value, element) {
		return this.optional(element) || /^[a-zA-Z0-9]+$/i.test(value);
	});

	jQuery.validator.addMethod('address', function (value, element) {
		return this.optional(element) || /^[-#.,' a-zA-Z0-9/\\]{2,}$/i.test(value);
	});

	jQuery.validator.addMethod('hasReservedDomain', function (value, element) {
		var domain = value.substring(value.lastIndexOf('@') + 1);
		if (domain.indexOf("k12") >= 0 || domain.indexOf(".edu") >= 0) {
			return false;
		}
		return true;
	});

	jQuery.validator.addMethod('strongPassword', function (value, element) {
		return this.optional(element) || /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i.test(value);
	});

	jQuery.validator.addMethod('isValidEmail', function (value, element) {
		if (value.toLowerCase().includes('.com.com')) {
			return false;
		}
		var regexTestResult = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(value);
		return this.optional(element) || regexTestResult;
	}, $("#email").data("msg-email"));

	$.validator.addMethod('filesize', function (value, element, param) {
		return this.optional(element) || element.files[0].size <= param;
	});

	jQuery.validator.addMethod('isValidDate', function (value, element, param) {
		var dob = '';
		$.each($('.date-group input'), function () {
			if ($(this).val() != '' && $(this).val() != null && $(this).val() != undefined && $(this).val() != 'value') {
				dob = dob += $(this).val() + '/';
			}
		});

		dob = dob.slice(0, -1).trim();
		if (isDate(dob)) {
			return true;
		} else {
			return false;
		}
	}, $("#year").data("msg-isValidDate"));

	//check if is a valid date, eg: feb 31 does not exist
	function isDate(txtDate) {
		var currVal = txtDate;
		if (currVal == '') return false;

		//Declare Regex 
		var rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/;
		var dtArray = currVal.match(rxDatePattern); // is format OK?

		if (dtArray == null) return false;

		//Checks for mm/dd/yyyy format.
		dtMonth = dtArray[1];
		dtDay = dtArray[3];
		dtYear = dtArray[5];

		if (dtMonth < 1 || dtMonth > 12) return false;else if (dtDay < 1 || dtDay > 31) return false;else if ((dtMonth == 4 || dtMonth == 6 || dtMonth == 9 || dtMonth == 11) && dtDay == 31) return false;else if (dtMonth == 2) {
			var isleap = dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0);
			if (dtDay > 29 || dtDay == 29 && !isleap) return false;
		}

		return true;
	}
}); //function