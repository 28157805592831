'use strict';

//-------------------------------------------------
function checkrules() {
	//document.getElementById('validationError').style.display = 'none';
	remove_term_options();
	add_term_options();
	var age = document.getElementById('age').value;
	var coverage = document.getElementById('coverage').value;
	// LOGIC TO REMOVE 20 YRS TERM FOR 61 TO 65 AND REMOVE 20 YRS, 15YRS FOR 66 TO 70
	if (age >= 61 && age <= 65) document.getElementById('term').remove(3);
	if (age >= 66 && age <= 70) {
		document.getElementById('term').remove(3);
		document.getElementById('term').remove(2);
	}
	// ADDITIONAL TERM REMOVE LOGIC
	if (age >= 19 && age <= 29 && coverage == 25000) document.getElementById('term').remove(3);
	if (age >= 19 && age <= 37 && coverage == 25000) document.getElementById('term').remove(2);
	if (age >= 19 && age <= 40 && coverage == 50000) document.getElementById('term').remove(1);
	if (age >= 19 && age <= 42 && coverage == 25000) document.getElementById('term').remove(1);
	// REMOVING COVERAGE 25000 ALTOGETHER WHEN AGE BETWEEN 19 TO 29 AND ADDING IT BACK IF USER LATER GOES TO A HIGHER AGE
	if (age >= 19 && age <= 29) {
		if (document.getElementById('coverage').options[1].value == '25000') document.getElementById('coverage').remove(1);
	} else {
		if (document.getElementById('coverage').options[1].value != '25000') {
			var optn = document.createElement('OPTION');
			optn.text = '$25,000';
			optn.value = 25000;
			document.getElementById('coverage').options.add(optn, 1);
		}
	}
	if (age != '-1' && coverage != '-1') {
		//document.getElementById("termtable").style.display = "block";
		$('#termtable').show();
		$('#term').change();
		$('#termtable .custom.dropdown').css('width', '110');
		$('#termtable .custom.dropdown ul').css('width', '108');
	} else {
		//document.getElementById("termtable").style.display = "none";
		$('#termtable').hide();
	}
}
if ($(".loan-listing").length > 0) {
	var removePanelBtn = '<button type="button" class="remove-panel btn-link">— &nbsp; Remove</button>';
	var panelCalculator = $(".panel-container").find(".row.loan").first().prop('outerHTML');
	var addPanelBtn = $(".add-panel");

	$(document).on('click', '.loan-listing .add-panel', function (e) {
		var $panelContainer = $(".panel-container");
		$panelContainer.append(panelCalculator);
		$panelContainer.find(".row.loan form").last().append(removePanelBtn);

		var panelCount = $panelContainer.children(".row").length;
		if (panelCount < 8) {
			$panelContainer.append($(".add-panel"));
		} else {
			$(".add-panel").remove();
		}
		addValidationsToNewPanel($panelContainer.find('.row.loan form').last());
	});

	$('.loan-listing form').each(function () {
		addValidationsToNewPanel(this);
	});

	$(document).on('click', '.loan-listing .remove-panel', function (e) {
		$(this).closest(".loan").remove();

		var panelCount = $(".panel-container").children(".row").length;
		if (panelCount == 7) {
			$(".panel-container").append(addPanelBtn);
		}
		recalculateDebt();
	});

	$(document).on('keyup', '.loan .balance, .loan .monthly, .loan .apr', function (e) {
		recalculateDebt();
	});

	$(document).on('keyup', '.loan .balance', function (e) {
		recalculateConsolidatedDebt();
	});

	$(document).on('keyup', '.loan .monthly', function (e) {
		var monthlyPaymentTotal = Number($(".monthly-payments h2>span").text().replace(/[^0-9.-]+/g, ""));
		$(".second-payment h2>span").text(monthlyPaymentTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
		calculateMonthlySavings();
	});

	$(".explore-options").on('click', function () {
		customScrollToByClass("saving-options");
	});
}

if ($(".saving-options").length > 0) {
	var $inputSlider = $("#apr-range");
	$inputSlider.on('input', function () {

		$inputSlider.removeClass("round-r-borders");
		$inputSlider.removeClass("round-l-borders");

		$(".slider-value span").html(this.value + "%");
		var widthCal = 50;
		widthCal += 3.14 * (this.value - 15);
		if (this.value == 30) {
			$inputSlider.addClass("round-r-borders");
		}if (this.value == 0) {
			$inputSlider.addClass("round-l-borders");
		}
		$(".slider-value span").css("left", "" + widthCal + "%");
		recalculateConsolidatedDebt();
		$(".apr-value").text(this.value + "%");
	});

	$(".loan-terms button").on("click", function (e) {
		e.preventDefault();
		$(".loan-terms button").removeClass("selected");
		$(this).toggleClass("selected");
		$(".months-value").text($(this).text());
		recalculateConsolidatedDebt();
	});
}

function recalculateDebt() {
	var totalLoanBalance = 0;
	var totalProduct = 0;
	var totalMonthly = 0;
	var finalApr = 0;

	$('.loan').each(function () {
		var loan = Number($(this).find(".balance").val());
		if (loan > 0) {
			totalLoanBalance += loan;
			var interest = Number($(this).find(".apr").val());
			if (interest > 0) {
				totalProduct += loan * (interest / 100);
			}
		}
		totalMonthly += Number($(this).find(".monthly").val());
	});

	if (totalLoanBalance > 0) {
		finalApr = totalProduct / totalLoanBalance * 100;
	}
	switch (decimalCount(finalApr)) {
		case 0:
			finalApr;
			break;
		case 1:
			finalApr = finalApr.toFixed(1);
			break;
		case 2:
			finalApr = finalApr.toFixed(2);
			break;
		default:
			var decimals = finalApr.toFixed(3).toString().split('.')[1].slice(0, 3);
			finalApr = finalApr.toString().split('.')[0] + "." + decimals;
	}

	$(".remaining-balance h2>span").text(totalLoanBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
	$(".monthly-payments h2>span").text(totalMonthly.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
	$(".average-apr h2>span").text(finalApr);

	if (totalLoanBalance < 1000 || totalLoanBalance > 30000) {
		$(".disclaimer").show();
	} else {
		$(".disclaimer").hide();
	}
}

function recalculateConsolidatedDebt() {
	var remainingBalanceTotal = Number($(".remaining-balance h2>span").text().replace(/[^0-9.-]+/g, ""));

	if (remainingBalanceTotal > 0) {
		var rangeVal = Number($("#apr-range").val());
		if (rangeVal != 0) {
			var loanTerm = Number($(".loan-terms button.selected").text());
			var monthlyRate = rangeVal / 100 / 12;
			var compareRate = Math.pow(1 + monthlyRate, loanTerm);
			var consolidatedTotal = remainingBalanceTotal * (monthlyRate * compareRate) / (compareRate - 1);
			$(".first-payment h2>span").text(consolidatedTotal.toFixed(2));
			calculateMonthlySavings();
		} else {
			$(".first-payment h2>span").text("0.00");
			$(".savings-value").text("$0.00");
		}
	} else {
		$(".first-payment h2>span").text("0.00");
		$(".savings-value").text("$0.00");
	}
}

function calculateMonthlySavings() {
	var monthlyTotal = Number($(".second-payment h2>span").text());
	var firstPaymentTotal = Number($(".first-payment h2>span").text());
	if (monthlyTotal == 0) {
		$(".savings-value").text("-$" + firstPaymentTotal.toFixed(2));
	} else {
		$(".savings-value").text("$" + (monthlyTotal - firstPaymentTotal).toFixed(2));
	}
}

function addValidationsToNewPanel(formReference) {
	$(formReference).validate({
		onkeyup: function onkeyup(element) {
			this.element(element);
		},
		highlight: function highlight(element, errorClass, validClass) {
			$(element).parents("div[class*='col-md']").addClass("error");
		},
		unhighlight: function unhighlight(element, errorClass, validClass) {
			$(element).parents(".error").removeClass("error");
		},
		errorPlacement: function errorPlacement(error, element) {}
	});
}

var decimalCount = function decimalCount(num) {
	var numStr = String(num);
	if (numStr.includes('.')) {
		return numStr.split('.')[1].length;
	};
	return 0;
};