"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

!function (t, a, e) {
  "function" == typeof define && define.amd ? define(["jquery"], t) : "object" == (typeof exports === "undefined" ? "undefined" : _typeof(exports)) && "undefined" == typeof Meteor ? module.exports = t(require("jquery")) : t(a || e);
}(function (o) {
  "use strict";
  function i(u, M, y) {
    var b = { invalid: [], getCaret: function getCaret() {
        try {
          var t = 0,
              a = u.get(0),
              e = document.selection,
              a = a.selectionStart;return e && -1 === navigator.appVersion.indexOf("MSIE 10") ? ((e = e.createRange()).moveStart("character", -b.val().length), t = e.text.length) : !a && "0" !== a || (t = a), t;
        } catch (t) {}
      }, setCaret: function setCaret(t) {
        try {
          var a;u.is(":focus") && ((a = u.get(0)).setSelectionRange ? a.setSelectionRange(t, t) : ((a = a.createTextRange()).collapse(!0), a.moveEnd("character", t), a.moveStart("character", t), a.select()));
        } catch (t) {}
      }, events: function events() {
        u.on("keydown.mask", function (t) {
          u.data("mask-keycode", t.keyCode || t.which), u.data("mask-previus-value", u.val()), u.data("mask-previus-caret-pos", b.getCaret()), b.maskDigitPosMapOld = b.maskDigitPosMap;
        }).on(o.jMaskGlobals.useInput ? "input.mask" : "keyup.mask", b.behaviour).on("paste.mask drop.mask", function () {
          setTimeout(function () {
            u.keydown().keyup();
          }, 100);
        }).on("change.mask", function () {
          u.data("changed", !0);
        }).on("blur.mask", function () {
          r === b.val() || u.data("changed") || u.trigger("change"), u.data("changed", !1);
        }).on("blur.mask", function () {
          r = b.val();
        }).on("focus.mask", function (t) {
          !0 === y.selectOnFocus && o(t.target).select();
        }).on("focusout.mask", function () {
          y.clearIfNotMatch && !s.test(b.val()) && b.val("");
        });
      }, getRegexMask: function getRegexMask() {
        for (var t, a, e, n, s, r = [], o = 0; o < M.length; o++) {
          (e = w.translation[M.charAt(o)]) ? (t = e.pattern.toString().replace(/.{1}$|^.{1}/g, ""), a = e.optional, (e = e.recursive) ? (r.push(M.charAt(o)), n = { digit: M.charAt(o), pattern: t }) : r.push(a || e ? t + "?" : t)) : r.push(M.charAt(o).replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"));
        }return s = r.join(""), n && (s = s.replace(new RegExp("(" + n.digit + "(.*" + n.digit + ")?)"), "($1)?").replace(new RegExp(n.digit, "g"), n.pattern)), new RegExp(s);
      }, destroyEvents: function destroyEvents() {
        u.off(["input", "keydown", "keyup", "paste", "drop", "blur", "focusout", ""].join(".mask "));
      }, val: function val(t) {
        var a = u.is("input") ? "val" : "text",
            a = 0 < arguments.length ? (u[a]() !== t && u[a](t), u) : u[a]();return a;
      }, calculateCaretPosition: function calculateCaretPosition(t) {
        var a = b.getMasked(),
            e = b.getCaret();if (t !== a) {
          for (var n = u.data("mask-previus-caret-pos") || 0, s = a.length, t = t.length, r = 0, o = 0, i = 0, l = 0, c = 0, c = e; c < s && b.maskDigitPosMap[c]; c++) {
            o++;
          }for (c = e - 1; 0 <= c && b.maskDigitPosMap[c]; c--) {
            r++;
          }for (c = e - 1; 0 <= c; c--) {
            b.maskDigitPosMap[c] && i++;
          }for (c = n - 1; 0 <= c; c--) {
            b.maskDigitPosMapOld[c] && l++;
          }t < e ? e = 10 * s : e <= n && n !== t ? b.maskDigitPosMapOld[e] || (t = e, e -= l - i, e -= r, b.maskDigitPosMap[e] && (e = t)) : n < e && (e += i - l, e += o);
        }return e;
      }, behaviour: function behaviour(t) {
        t = t || window.event, b.invalid = [];var a = u.data("mask-keycode");if (-1 === o.inArray(a, w.byPassKeys)) {
          var e = b.getMasked(),
              a = b.getCaret(),
              n = u.data("mask-previus-value") || "";return setTimeout(function () {
            b.setCaret(b.calculateCaretPosition(n));
          }, o.jMaskGlobals.keyStrokeCompensation), b.val(e), b.setCaret(a), b.callbacks(t);
        }
      }, getMasked: function getMasked(t, a) {
        for (var e, n, s = [], r = void 0 === a ? b.val() : a + "", o = 0, i = M.length, l = 0, c = r.length, u = 1, k = "push", p = -1, d = 0, f = [], h = y.reverse ? (k = "unshift", u = -1, e = 0, o = i - 1, l = c - 1, function () {
          return -1 < o && -1 < l;
        }) : (e = i - 1, function () {
          return o < i && l < c;
        }); h();) {
          var v = M.charAt(o),
              g = r.charAt(l),
              m = w.translation[v];m ? (g.match(m.pattern) ? (s[k](g), m.recursive && (-1 === p ? p = o : o === e && o !== p && (o = p - u), e === p && (o -= u)), o += u) : g === n ? (d--, n = void 0) : m.optional ? (o += u, l -= u) : m.fallback ? (s[k](m.fallback), o += u, l -= u) : b.invalid.push({ p: l, v: g, e: m.pattern }), l += u) : (t || s[k](v), g === v ? (f.push(l), l += u) : (n = v, f.push(l + d), d++), o += u);
        }a = M.charAt(e);i !== c + 1 || w.translation[a] || s.push(a);a = s.join("");return b.mapMaskdigitPositions(a, f, c), a;
      }, mapMaskdigitPositions: function mapMaskdigitPositions(t, a, e) {
        var n = y.reverse ? t.length - e : 0;b.maskDigitPosMap = {};for (var s = 0; s < a.length; s++) {
          b.maskDigitPosMap[a[s] + n] = 1;
        }
      }, callbacks: function callbacks(t) {
        function a(t, a, e) {
          "function" == typeof y[t] && a && y[t].apply(this, e);
        }var e = b.val(),
            n = e !== r,
            s = [e, t, u, y];a("onChange", !0 == n, s), a("onKeyPress", !0 == n, s), a("onComplete", e.length === M.length, s), a("onInvalid", 0 < b.invalid.length, [e, t, u, b.invalid, y]);
      } };u = o(u);var s,
        w = this,
        r = b.val();M = "function" == typeof M ? M(b.val(), void 0, u, y) : M, w.mask = M, w.options = y, w.remove = function () {
      var t = b.getCaret();return w.options.placeholder && u.removeAttr("placeholder"), u.data("mask-maxlength") && u.removeAttr("maxlength"), b.destroyEvents(), b.val(w.getCleanVal()), b.setCaret(t), u;
    }, w.getCleanVal = function () {
      return b.getMasked(!0);
    }, w.getMaskedVal = function (t) {
      return b.getMasked(!1, t);
    }, w.init = function (t) {
      if (t = t || !1, y = y || {}, w.clearIfNotMatch = o.jMaskGlobals.clearIfNotMatch, w.byPassKeys = o.jMaskGlobals.byPassKeys, w.translation = o.extend({}, o.jMaskGlobals.translation, y.translation), w = o.extend(!0, {}, w, y), s = b.getRegexMask(), t) b.events(), b.val(b.getMasked());else {
        y.placeholder && u.attr("placeholder", y.placeholder), u.data("mask") && u.attr("autocomplete", "off");for (var a = 0, e = !0; a < M.length; a++) {
          var n = w.translation[M.charAt(a)];if (n && n.recursive) {
            e = !1;break;
          }
        }e && u.attr("maxlength", M.length).data("mask-maxlength", !0), b.destroyEvents(), b.events();t = b.getCaret();b.val(b.getMasked()), b.setCaret(t);
      }
    }, w.init(!u.is("input"));
  }o.maskWatchers = {};function a() {
    var t = o(this),
        a = {},
        e = "data-mask-",
        n = t.attr("data-mask");if (t.attr(e + "reverse") && (a.reverse = !0), t.attr(e + "clearifnotmatch") && (a.clearIfNotMatch = !0), "true" === t.attr(e + "selectonfocus") && (a.selectOnFocus = !0), l(t, n, a)) return t.data("mask", new i(this, n, a));
  }var l = function l(t, a, e) {
    e = e || {};var n = o(t).data("mask"),
        s = JSON.stringify,
        r = o(t).val() || o(t).text();try {
      return "function" == typeof a && (a = a(r)), "object" != (typeof n === "undefined" ? "undefined" : _typeof(n)) || s(n.options) !== s(e) || n.mask !== a;
    } catch (t) {}
  };o.fn.mask = function (t, a) {
    a = a || {};function e() {
      if (l(this, t, a)) return o(this).data("mask", new i(this, t, a));
    }var n = this.selector,
        s = o.jMaskGlobals,
        r = s.watchInterval,
        s = a.watchInputs || s.watchInputs;return o(this).each(e), n && "" !== n && s && (clearInterval(o.maskWatchers[n]), o.maskWatchers[n] = setInterval(function () {
      o(document).find(n).each(e);
    }, r)), this;
  }, o.fn.masked = function (t) {
    return this.data("mask").getMaskedVal(t);
  }, o.fn.unmask = function () {
    return clearInterval(o.maskWatchers[this.selector]), delete o.maskWatchers[this.selector], this.each(function () {
      var t = o(this).data("mask");t && t.remove().removeData("mask");
    });
  }, o.fn.cleanVal = function () {
    return this.data("mask").getCleanVal();
  }, o.applyDataMask = function (t) {
    ((t = t || o.jMaskGlobals.maskElements) instanceof o ? t : o(t)).filter(o.jMaskGlobals.dataMaskAttr).each(a);
  };var t,
      e,
      n,
      s = { maskElements: "input,td,span,div", dataMaskAttr: "[data-mask]", dataMask: !0, watchInterval: 300, watchInputs: !0, keyStrokeCompensation: 10, useInput: !/Chrome\/[2-4][0-9]|SamsungBrowser/.test(window.navigator.userAgent) && (t = "input", n = document.createElement("div"), (e = (t = "on" + t) in n) || (n.setAttribute(t, "return;"), e = "function" == typeof n[t]), n = null, e), watchDataMask: !1, byPassKeys: [9, 16, 17, 18, 36, 37, 38, 39, 40, 91], translation: { 0: { pattern: /\d/ }, 9: { pattern: /\d/, optional: !0 }, "#": { pattern: /\d/, recursive: !0 }, A: { pattern: /[a-zA-Z0-9]/ }, S: { pattern: /[a-zA-Z]/ } } };o.jMaskGlobals = o.jMaskGlobals || {}, (s = o.jMaskGlobals = o.extend(!0, {}, s, o.jMaskGlobals)).dataMask && o.applyDataMask(), setInterval(function () {
    o.jMaskGlobals.watchDataMask && o.applyDataMask();
  }, s.watchInterval);
}, window.jQuery, window.Zepto);